
import { createBrowserRouter } from 'react-router-dom'
import HomePage from './Components/HomePage'
import AppLayout from './AppLayout'
import AboutPage from './Components/AboutPage'
import IsoCertificationProcess from './Components/IsoCertificationProcess'
import OurPolicy from './Components/OurPolicy'
import ContactUs from './Components/ContactUs'
import IsoCertServices from './Components/IsoCertServices'
import CertStatus from './Components/CertStatus'
import Franchise from './Components/Franchise'
import Trainings from './Components/Trainings'
import IsoTraining from './Components/IsoTraining'
import LeanTraining from './Components/LeanTraining'
import SampleCerts from './Components/SampleCerts'
import AdminLogin from './AdminComponents/AdminLogin'
import AdminLayout from './AdminComponents/AdminLayout'
import Dashboard from './AdminComponents/Dashboard'
import PrivateRoute from './Utils/PrivateRoute'
import AddServices from './AdminComponents/AddServices'
import AddCertificate from './AdminComponents/AddCertificate'
import AddFraudulantActivityNotice from './AdminComponents/AddFraudulantActivityNotice'
import FraudulantActivityNotice from './Components/FraudulantActivityNotice'
import Announcements from './AdminComponents/Announcements'
export const AppRouter = createBrowserRouter([
    {
        path: '/',
        element: <AppLayout/>,
        children : [
            {
                path: '/',
                element: <HomePage/>
            },
            {
                path: 'about-us',
                element: <AboutPage/>
            },
            {
                path: 'iso-certification-process',
                element: <IsoCertificationProcess/>
            },
            {
                path: 'our-policy',
                element: <OurPolicy/>
            },
            {
                path: 'contact-us',
                element: <ContactUs/>
            },
            {
                path: 'iso-certifications',
                element: <IsoCertServices/>
            },
            {
                path: 'certification-status',
                element: <CertStatus/>
            },
            {
                path: 'franchise',
                element: <Franchise/>
            },
            {
                path: 'trainings',
                element: <Trainings/>
            },
            {
                path: 'iso-certification',
                element: <IsoTraining/>
            },
            {
                path: 'lean-training',
                element: <LeanTraining/>
            },
            {
                path: 'sample-certificates',
                element: <SampleCerts/>
            },
            {
                path: 'fraudulant-activity-notice',
                element: <FraudulantActivityNotice/>
            },
        ]
    },
    {
        path: 'gc-admin',
        element: <AdminLogin/>
    },
    {
        path: 'admin',
        element: <PrivateRoute Component={AdminLayout}/>,
        children: [
            {
                path: 'dashboard',
                element: <Dashboard/>
            },
            {
                path: 'addservice',
                element: <AddServices/>
            },
            {
                path: 'addcertificate',
                element: <AddCertificate/>
            },
            {
                path: 'addfraudulantactivitynotice',
                element: <AddFraudulantActivityNotice/>
            },
            {
                path: 'announcements',
                element: <Announcements/>
            },
        ]
    },
])