import { Button, FormControl, FormLabel, Input, Textarea } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaPhoneVolume } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import { LuBadgeCheck } from "react-icons/lu";
import usePost from '../Utils/usePost';
import { ContactFormSchema } from '../Utils/FormValidation';

const Footer = () => {
  const { IsPostLoading, PostData } = usePost('/api/ContactUs/SendEmailBySendGrid');

  const [FormErrors, setFormErrors] = useState({});
  const [ContactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    contactNo: "",
    message: ""
  });

  const [ShowMsg, setShowMsg] = useState(false);

  const SaveContactForm = async (e) => {
     e.preventDefault();
     setFormErrors({});

     ContactFormSchema.validate(ContactDetails,{abortEarly:false})
     .then(async() => {

     const FormBody = new FormData();

     FormBody.append('EmailSubject','New Form Submission')
     FormBody.append('EmailBody',`<div>
     <p><b>Name: </b> ${ContactDetails.name}<br/>
      <b>Email Address: </b> ${ContactDetails.email}<br/>
      <b>Contact No.: </b> ${ContactDetails.contactNo}<br/>
      <b>Message: </b> ${ContactDetails.message}</p>
     </div>`)

      console.log("Email Body",FormBody);
      const response = await PostData(FormBody);
      console.log("response",response);

      if(response){
        setContactDetails({
          name: "",
          email: "",
          contactNo: "",
          message: ""
        });
        setShowMsg(true);
        setTimeout(() => {
          setShowMsg(false);
        }, 3000);
        
      }

    }).catch((validationErrors) => {
      const newErrors = {};
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setFormErrors(newErrors);
    });
      

  }

  return (
      <footer className="text-gray-600 bg-blue-50">
  <div className="max-w-screen-xl border-gray-300 border-b px-5 py-20 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
    <div className="w-64 flex-shrink-0 space-y-4 md:mx-0 mx-auto text-center md:text-left">
      <Link to={'/'} className="flex md:justify-start justify-center">
        <img src="/images/globusLogo.png" alt="globus logo" />
      </Link>
      <p className="text-lg font-bold">Globus Certifications Pvt. Ltd.</p>
      <p className=" text-sm text-gray-500">Globus is an ISO Certification Company in Delhi NCR, India. We help with ISO Standard Certification and ISO Training.</p>

    </div>
    <div className="flex-grow flex flex-wrap  -mb-10 md:mt-0 mt-10 md:text-left text-center">
    <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className=" font-semibold text-gray-800 tracking-widest text-lg mb-3">Contact Details</h2>
        <div className='space-y-2'>
          <h6 className='font-medium'>Address</h6>
        <p className=" text-sm text-gray-500">Innov8 Ras Villas, Lower Ground Floor, Salcon Rasvillas,D-1, Saket District Center, Saket, New Delhi 110017, India</p>
        <h6 className='font-medium'>Contact</h6>
        <p className=" text-sm text-gray-500"><FaPhoneVolume className='inline-block'/> +91-9999763637, +91-9918728945</p>
        <p className=" text-sm text-gray-500"><FaEnvelope className='inline-block'/> info@gcert.co</p>
        <h6 className='font-medium'>Connect with us on</h6>
        
      <span className="inline-flex sm:ml-auto space-x-3 justify-center sm:justify-start">
        <a href='https://www.facebook.com/gcerts/' target='_blank' rel="noreferrer"  className="text-gray-500 hover:text-amber-600">
          <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </a>
        <a href='https://twitter.com/qacerts/' target='_blank' rel="noreferrer"  className="text-gray-500 hover:text-amber-600">          <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a>
        {/* <a href='https://www.facebook.com/gcerts/' target='_blank' rel="noreferrer"  className="text-gray-500 hover:text-amber-600">          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
          </svg>
        </a> */}
        <a href='https://www.linkedin.com/in/globus-certifications-27b713196/' target='_blank' rel="noreferrer"  className="text-gray-500 hover:text-amber-600">          <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
        </div>
      </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4 md:mt-0 mt-10">
      <h2 className=" font-semibold text-gray-800 tracking-widest text-lg mb-3">Quick Links</h2>
        <nav className="list-none mb-10 space-y-2">
          <li>
            <Link to={'/about-us'} className="text-gray-600 hover:text-gray-800 hover:underline hover:font-medium">About Us</Link>
          </li>
          <li>
            <Link to={'/iso-certification-process'} className="text-gray-600 hover:text-gray-800 hover:underline hover:font-medium">Process</Link>
          </li>
          <li>
            <Link to={'/trainings'} className="text-gray-600 hover:text-gray-800 hover:underline hover:font-medium">Training</Link>
          </li>
          <li>
            <Link to={'/contact-us'} className="text-gray-600 hover:text-gray-800 hover:underline hover:font-medium">Contact Us</Link>
          </li>
        </nav>
      </div>

      <div className=" lg:w-1/2 w-full px-4 md:mt-0 mt-10">
      {/* <h2 className=" font-semibold text-gray-800 tracking-widest text-lg mb-3">Have a query?</h2> */}
        {ShowMsg ? <div className='text-center bg-white bg-opacity-30 border-2 py-8 border-dashed rounded-3xl'>
          <div className='space-y-2 my-auto'>
            <LuBadgeCheck size={'6rem'} className='mx-auto text-green-500 animate-bounce'/>
            <h6 className='text-3xl font-bold text-gray-800'>Thank You!!</h6>
            <p className='text-gray-500'>We will get back to you as soon as possible.</p>
          </div>
        </div>: <form onSubmit={SaveContactForm} className='space-y-6'>
          <FormControl variant={'floatingFooter'}>
            <Input isInvalid={FormErrors?.name} value={ContactDetails?.name} onChange={({target})=>{
              setContactDetails(
                {
                  ...ContactDetails,
                  name: target.value
                }
              )
            }} variant={'flushed'} borderColor={'gray.400'} placeholder='' />
            <FormLabel>Name</FormLabel>
            {FormErrors?.name && <span className='text-red-600'>{FormErrors?.name}</span>}
          </FormControl>
          <FormControl variant={'floatingFooter'}>
            <Input isInvalid={FormErrors?.email} type='email' value={ContactDetails?.email} onChange={({target})=>{
              setContactDetails(
                {
                  ...ContactDetails,
                  email: target.value
                }
              )
            }} variant={'flushed'} borderColor={'gray.400'} placeholder='' />
            <FormLabel>Email</FormLabel>
            {FormErrors?.email && <span className='text-red-600'>{FormErrors?.email}</span>}
          </FormControl>
          <FormControl variant={'floatingFooter'}>
            <Input isInvalid={FormErrors?.contactNo} type='number' value={ContactDetails?.contactNo} onChange={({target})=>{
              setContactDetails(
                {
                  ...ContactDetails,
                  contactNo: target.value
                }
              )
            }} variant={'flushed'} borderColor={'gray.400'} placeholder='' />
            <FormLabel>Contact No.</FormLabel>
            {FormErrors?.contactNo && <span className='text-red-600'>{FormErrors?.contactNo}</span>}
          </FormControl>
          <FormControl variant={'floatingFooter'}>
            <Textarea isInvalid={FormErrors?.message} value={ContactDetails?.message} onChange={({target})=>{
              setContactDetails(
                {
                  ...ContactDetails,
                  message: target.value
                }
              )
            }} rows={2} variant={'flushed'} borderColor={'gray.400'} placeholder='' />
            <FormLabel>Message</FormLabel>
            {FormErrors?.message && <span className='text-red-600'>{FormErrors?.message}</span>}
          </FormControl>
          <Button isLoading={IsPostLoading} loadingText="Submitting..." type='submit' colorScheme='orange'>Submit</Button>
        </form>}
      </div>
    </div>
  </div>
  <div className="bg-blue-50">
    <div className="max-w-screen-xl mx-auto py-4 px-5 flex flex-wrap justify-center flex-col sm:flex-row">
      <p className="text-gray-500 text-sm text-center sm:text-left">Copyright © 2018 | Globus Certifications. All rights reserved.</p>
      {/* <p>Developed By: <a href='http://www.sagoni.com/' target='_blank' rel="noreferrer" className='font-semibold'>Sagoni</a></p> */}
    </div>
  </div>
</footer>
  )
}

export default Footer